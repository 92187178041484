<template>
  <b-card-code>
    <validation-observer ref="GroupAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addProject">
        <!-- Organization-->
        <b-form-group
          v-if="is_staff"
          label="Organization"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <v-select
              v-model="organization"
              label="text"
              placeholder="--Select Organization--"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              @input="onOrgChange"
              autocomplete
              :reduce="(organization) => organization.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Project Name -->
        <b-form-group label="Project Name" label-for="project_name">
          <validation-provider
            #default="{ errors }"
            name="Project Name"
            :rules="{ regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/ }"
          >
            <b-form-input
              id="project_name"
              v-model="project_name"
              :state="errors.length > 0 ? false : null"
              name="project_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- cdr_id -->
        <b-form-group label="CDR ID" label-for="cdr">
          <b-form-input id="cdr" v-model="cdr_id" name="cdr" />
        </b-form-group>
        <b-form-group label="Start Date" label-for="Start Date">
          <flat-pickr
            placeholder="Start Date"
            v-model="startDate"
            class="form-control"
          />
        </b-form-group>
        <b-form-group label="End Date" label-for="End Date">
          <flat-pickr
            placeholder="End Date"
            v-model="endDate"
            class="form-control"
          />
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="invalid" size="sm">
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      project_name: "",
      cdr_id: "",
      startDate: "",
      endDate: "",
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: null,
      // organization:this.$store.state.app.org_id,
      organizations: [],
    };
  },
  created: function () {
    this.load();
    this.onOrgChange();
  },
  methods: {
    load: function () {
      if (this.is_staff) {
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org/?q_org_id=" +
            this.organization,
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
        });
      }
    },
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
    },
    addProject: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          let data = {
            project_name: this.project_name,
            cdr_id: this.cdr_id,
            start_date: this.startDate,
            end_date: this.endDate,
            org_id: this.organization,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "project/project/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.project_id) {
              this.$router.push({ name: "List Projects" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added Project!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>